import { AppEnv, EnvProps } from "./env-types";
import { npdEnvDefaults } from "./env-defaults";
import { merge } from "lodash";

const env: EnvProps = merge(npdEnvDefaults, {
  ...npdEnvDefaults,
  appEnv: AppEnv.DEV,
  baseURL: "https://lineupcardsdev-api.mlbinfra.com",
});

window.MLBBest = { env };
